import React from 'react'
import { FaVideo } from 'react-icons/fa';
export default function ThankYou() {
  return (
    <div>
    <div style={{ height: "100vh" }} className="modal-body successful">
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "auto" }}>
        <div>
          <span style={{ fontSize: "22px" }}> Thank you ! </span>
          <br /> Your form has been Successfully Submitted. <br />
          <p className="second-p" style={{ fontWeight: "700", color: "white" }}>
            We will Contact you Soon.
          </p>
          <span className="main-p"> Book a Meeting </span>
        </div>
        <a
          style={{ width: "300px" }}
          href="https://calendly.com/metablock-sale"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="card-formses">
            <FaVideo className="video-icon" />
            Schedule Free 30-Minute Meeting
          </div>
        </a>
        <button 
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer"
          }}
          onClick={() => window.location.href = "/"}
        >
          Go to Home
        </button>
      </div>
    </div>
  </div>
  
  )
}
